import store from '@/store/index'
import calls from './calls'
import tokens from './tokens'

function logError(err) {
  console.error(err)
}

function updateLoginState(loggedIn) {
  if (loggedIn) {
    store.dispatch('loginUser')
  } else {
    store.dispatch('logoutUser')
  }
}

async function login(username, password) {
  let loggedIn = false
  try {
    const url = '/api/login'
    const loginresult = await calls.post(url, {
      username,
      password,
    })
    tokens.store(loginresult.data)
    loggedIn = true
    updateLoginState(loggedIn)
  } catch (error) {
    logError(error)
    loggedIn = false
  }

  return loggedIn
}

async function getSubscriptionPaymentLink() {
  let link = ''
  try {
    const url = '/api/subscription/paymentlink'
    const linkResult = await calls.get(url)
    link = linkResult.data.url
  } catch (error) {
    logError(error)
  }

  return link
}

async function register(newuser) {
  let registered = false
  let loggedIn = false
  let paymentLink = ''
  try {
    const url = '/api/registration/register'
    const result = await calls.post(url, newuser)
    registered = true
    loggedIn = await login(result.data.username, newuser.password)
    paymentLink = await getSubscriptionPaymentLink()
  } catch (error) {
    logError(error)
    registered = false
  }

  return {
    registered,
    loggedIn,
    paymentLink,
  }
}

export default {
  login,
  register,
}
